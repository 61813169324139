import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';
import { PlusIcon } from '@expo/styleguide-icons/outline/PlusIcon';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/compat/router';
import { useState } from 'react';

import { isOwnerOrAdminOrImplicitOwner, viewerHasPermissionOnAccount } from '~/common/helpers';
import { useAppsPaginatedQuery } from '~/graphql/queries/AppsPaginatedQuery.query.generated';
import { Permission } from '~/graphql/types.generated';
import { AllProjectsScene } from '~/scenes/Dashboard/Projects/AllProjectsScene';
import { ProjectCreationDialog } from '~/scenes/Dashboard/Projects/AllProjectsScene/ProjectCreationDialog';
import { DashboardLayout } from '~/scenes/Dashboard/components/DashboardLayout';
import { LoggedInProps, getServerSidePropsFor } from '~/scenes/_app/helpers';
import { withAuthAndAccountRequired } from '~/scenes/_app/withAuthAndAccountRequired';
import { Button } from '~/ui/components/Button';
import Document from '~/ui/components/Document';
import { Placeholder } from '~/ui/components/Placeholder';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { FOOTNOTE } from '~/ui/components/text';

export const PROJECTS_PAGE_SIZE = 10;

function ProjectsPageInner({
  accountName,
  currentUser,
  currentDateAsString,
  params: { create },
}: LoggedInProps) {
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(Boolean(create));
  const router = useRouter();
  const { data, loading, error, refetch, fetchMore } = useAppsPaginatedQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: PROJECTS_PAGE_SIZE,
      accountName,
    },
    fetchPolicy: 'cache-and-network',
  });

  const account = currentUser?.accounts?.find((account) => account.name === accountName);

  if (error || !data) {
    return (
      <Document meta={{ name: 'dashboard', pageName: 'Projects' }}>
        <DashboardLayout
          title="Projects"
          description="View all of the projects associated with your account."
          icon={FolderIcon}
          currentUser={currentUser}
          accountName={accountName}
          currentDateAsString={currentDateAsString}>
          <Placeholder
            loading={loading}
            description="An error has occurred. Refresh this page to try again."
            buttonText="Refresh"
            onClick={() => window.location.reload()}
          />
        </DashboardLayout>
      </Document>
    );
  }

  const hasPublishPermission = account
    ? viewerHasPermissionOnAccount(account, Permission.Publish) ||
      isOwnerOrAdminOrImplicitOwner(account, currentUser.username)
    : false;
  const canCreateProject = !loading && !error && hasPublishPermission;

  return (
    <Document meta={{ name: 'dashboard', pageName: 'Projects' }}>
      <DashboardLayout
        title="Projects"
        description="View all of the projects associated with your account."
        icon={FolderIcon}
        currentUser={currentUser}
        currentDateAsString={currentDateAsString}
        accountName={accountName}
        rightSlot={
          <TooltipRoot>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  setIsCreateFormVisible(true);
                }}
                leftSlot={<PlusIcon />}
                testID="new-project-button"
                disabled={!canCreateProject}>
                Create a Project
              </Button>
            </TooltipTrigger>
            {!canCreateProject && (
              <TooltipContent className="w-[200px]" side="bottom">
                <FOOTNOTE>
                  You do not have permissions to create new project on this account.
                </FOOTNOTE>
              </TooltipContent>
            )}
          </TooltipRoot>
        }>
        {hasPublishPermission && account && (
          <ProjectCreationDialog
            isCreateFormVisible={isCreateFormVisible}
            onRequestClose={() => {
              setIsCreateFormVisible(false);
            }}
            accountId={account.id}
            accountName={accountName}
            refetchProjects={refetch}
            onComplete={(slug: string) => {
              void router?.push({
                pathname: '/accounts/[account]/projects/[projectName]',
                query: {
                  account: accountName,
                  projectName: slug,
                },
              });
            }}
          />
        )}
        <AllProjectsScene
          accountName={accountName}
          currentUser={currentUser}
          data={data}
          fetchMore={fetchMore}
          loading={loading}
          canCreateProject={canCreateProject}
          openProjectDialog={() => {
            setIsCreateFormVisible(true);
          }}
        />
      </DashboardLayout>
    </Document>
  );
}

const ProjectsPage = withAuthAndAccountRequired(ProjectsPageInner);

export async function getServerSideProps(context: GetServerSidePropsContext) {
  return await getServerSidePropsFor(context, ProjectsPage);
}

export default ProjectsPage;
