import { mergeClasses } from '@expo/styleguide';
import { DiscordIcon } from '@expo/styleguide-icons/custom/DiscordIcon';
import { ActivityIcon } from '@expo/styleguide-icons/outline/ActivityIcon';
import { Building05Icon } from '@expo/styleguide-icons/outline/Building05Icon';
import { LayoutAlt02Icon } from '@expo/styleguide-icons/outline/LayoutAlt02Icon';

import { EASShoutoutBanner } from 'scenes/Dashboard/Overview/components/EASShoutoutBanner';
import * as Analytics from '~/common/analytics';
import { getGenericErrorMessage, isDeveloperOrImplicitOwner } from '~/common/helpers';
import { useAccountTimelineActivityQuery } from '~/graphql/queries/AccountTimelineActivityQuery.generated';
import { ActivityTimelineProjectActivityType } from '~/graphql/types.generated';
import { useRefetchOnRefocus } from '~/providers/useRefetchOnRefocus';
import { DashboardLayout } from '~/scenes/Dashboard/components/DashboardLayout';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { BoxHeader } from '~/ui/components/Box/BoxHeader';
import { BoxWrapper } from '~/ui/components/Box/BoxWrapper';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { IconBoxed } from '~/ui/components/IconBoxed';
import { Placeholder } from '~/ui/components/Placeholder';
import { A, CALLOUT, H3 } from '~/ui/components/text';

import { OnboardingTile } from './OnboardingTile';
import { ActivityTimeline } from './components/ActivityTimeline';
import { ActivitySkeleton } from './components/ActivityTimeline/Activity';
import { AppJSBanner } from './components/AppJSBanner';
import { PlanNearOverageBanner } from './components/PlanNearOverageBanner';
import { RecentProjects } from './components/RecentProjects';
import { RecentSnacks } from './components/RecentSnacks';
import { useOnboardingTile } from './useOnboardingTile';

const pageSize = 6;

type Props = {
  accountName: string;
  currentUser: LoggedInProps['currentUser'];
  isYou: boolean;
  currentDateAsString: string;
};

export function DashboardOverviewScene({
  accountName,
  currentUser,
  isYou,
  currentDateAsString,
}: Props) {
  const { loading, data, error, fetchMore, refetch } = useAccountTimelineActivityQuery({
    variables: {
      accountName,
      first: pageSize,
      filter: {
        types: [
          ActivityTimelineProjectActivityType.Build,
          ActivityTimelineProjectActivityType.Submission,
          ActivityTimelineProjectActivityType.Update,
        ],
      },
    },
  });

  useRefetchOnRefocus(refetch);

  const account = currentUser.accounts.find((account) => account.name === accountName);
  const hasCreatePermissions = account
    ? isDeveloperOrImplicitOwner(account, currentUser.username)
    : false;

  const hasProjects = data?.account.byName.appsPaginated.edges.length !== 0;
  const hasEntries = data?.account.byName.timelineActivity.edges.length !== 0;

  const loadedWithNoActivity = !loading && !hasEntries;
  const firstTimeLoading = loading && !data;
  const loadedWithActivity = data && hasEntries;

  const tile = useOnboardingTile({ accountName, loadedWithNoActivity, hasProjects });

  function trackEvent(label: string) {
    Analytics.track(Analytics.events.EAS_SHOUTOUT, {
      action: Analytics.EASShoutoutActions.ONBOARDING_LINK_CLICK,
      label,
    });
  }

  if (error) {
    return (
      <DashboardLayout
        accountName={accountName}
        currentUser={currentUser}
        currentDateAsString={currentDateAsString}
        showContentSpotlight>
        <Placeholder
          loading={loading}
          description={getGenericErrorMessage()}
          buttonText="Refresh"
          onClick={() => window.location.reload()}
        />
      </DashboardLayout>
    );
  }

  const showDidYouKnow = hasCreatePermissions && tile.data;

  return (
    <DashboardLayout
      title="Dashboard"
      icon={LayoutAlt02Icon}
      accountName={accountName}
      currentUser={currentUser}
      currentDateAsString={currentDateAsString}
      showContentSpotlight
      topSlot={
        <div className="flex flex-col gap-6 empty:hidden">
          <EASShoutoutBanner
            currentDateAsString={currentDateAsString}
            accountName={accountName}
            currentUser={currentUser}
          />
          <AppJSBanner currentDateAsString={currentDateAsString} />
        </div>
      }>
      <div
        className={mergeClasses(
          'grid grid-cols-2 items-start gap-6',
          'max-lg-gutters:flex max-lg-gutters:flex-col-reverse max-lg-gutters:items-stretch'
        )}>
        <RecentProjects accountName={accountName} />
        <div
          className={mergeClasses(
            'grid grid-cols-2 grid-rows-[repeat(2,auto)] flex-col gap-6',
            !showDidYouKnow && 'grid-cols-1',
            'max-lg-gutters:flex'
          )}>
          {loading ? (
            <>
              {showDidYouKnow && (
                <ContentLoading
                  height={104}
                  width="100%"
                  containerClassName="col-span-2 flex-1"
                  loading
                />
              )}
              <ContentLoading height={86} width="100%" loading />
              <ContentLoading height={86} width="100%" loading />
            </>
          ) : (
            <>
              {showDidYouKnow && <OnboardingTile tile={tile.data} />}
              <BoxWrapper className="rounded-lg p-4">
                <BoxHeader
                  icon={<DiscordIcon />}
                  title="Discord"
                  size="sm"
                  description={
                    <CALLOUT theme="secondary">
                      Have any problems with using Expo? Join us on{' '}
                      <A
                        isStyled
                        href="https://chat.expo.dev"
                        onClick={() => trackEvent('discord')}>
                        Discord
                      </A>
                      .
                    </CALLOUT>
                  }
                />
              </BoxWrapper>
              <BoxWrapper className="rounded-lg p-4">
                <BoxHeader
                  icon={<Building05Icon />}
                  title="Office hours"
                  size="sm"
                  description={
                    <CALLOUT theme="secondary">
                      Get help during our weekly office hours!{' '}
                      <A
                        isStyled
                        href="https://us02web.zoom.us/meeting/register/tZcvceivqj0oHdGVOjEeKY0dRxCRPb0HzaAK"
                        onClick={() => trackEvent('office-hours')}>
                        Sign up here
                      </A>
                      .
                    </CALLOUT>
                  }
                />
              </BoxWrapper>
            </>
          )}
        </div>
      </div>
      <PlanNearOverageBanner
        accountName={accountName}
        currentDateAsString={currentDateAsString}
        currentUser={currentUser}
      />
      <div className="flex flex-col gap-6">
        <H3 className="flex items-center gap-3">
          <IconBoxed theme="secondary" icon={<ActivityIcon />} />
          Recent activity
        </H3>
        {loadedWithNoActivity && (
          <Placeholder
            title="No activity"
            description="Make some builds, updates or submissions to see them here."
          />
        )}
        {firstTimeLoading && (
          <div className="flex flex-col gap-6">
            <ActivitySkeleton line="default" />
            <ActivitySkeleton line="default" />
            <ActivitySkeleton line="default" />
            <ActivitySkeleton line="none" />
          </div>
        )}
        {loadedWithActivity && (
          <ActivityTimeline
            data={data}
            loading={loading}
            accountName={accountName}
            fetchMore={fetchMore}
          />
        )}
      </div>
      {isYou && <RecentSnacks accountName={accountName} />}
    </DashboardLayout>
  );
}
