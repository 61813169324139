import { Link } from '@expo/styleguide';
import { BuildIcon } from '@expo/styleguide-icons/custom/BuildIcon';
import { EasSubmitIcon } from '@expo/styleguide-icons/custom/EasSubmitIcon';
import { format } from 'date-fns/format';
import { Fragment, useEffect, useState } from 'react';

import { dateFnsFormats } from '~/common/constants';
import { getVariableTimeString } from '~/common/getVariableTimeString';
import { getProjectURL } from '~/common/helpers';
import { SubmissionStatus, TableSubmissionFragment } from '~/graphql/types.generated';
import {
  platformLabelMap,
  submissionStoreIconMap,
  submissionStoreMap,
} from '~/scenes/Submissions/shared';
import { ActorAttribution } from '~/ui/components/ActorAttribution';
import { ContentLoading } from '~/ui/components/ContentLoading';
import { StatusIndicator } from '~/ui/components/StatusIndicator';
import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TableEmptyCellText } from '~/ui/components/Table/TableEmptyCellText';
import { TableInlineCell } from '~/ui/components/Table/TableInlineCell';
import { TableRow } from '~/ui/components/Table/TableRow';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipText } from '~/ui/components/Tooltip/TooltipText';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { CALLOUT } from '~/ui/components/text';

type SubmissionRowProps = {
  submission: TableSubmissionFragment;
  accountName: string;
  disableLinks?: boolean;
  hideAuthor?: boolean;
  hideTimestamp?: boolean;
  isActivityView?: boolean;
};

export function SubmissionRow({
  submission,
  accountName,
  disableLinks,
  hideAuthor,
  hideTimestamp,
  isActivityView,
}: SubmissionRowProps) {
  const [renderDate, setRenderDate] = useState(false);

  useEffect(() => {
    setRenderDate(true);
  }, []);

  const emptyCell = (
    <TableCell>
      <TableEmptyCellText />
    </TableCell>
  );

  const StoreIcon = submissionStoreIconMap[submission.submissionPlatform] ?? EasSubmitIcon;

  const primaryCell = (
    <TableCell
      mobilePrimaryCell
      theme={disableLinks ? 'default' : 'interactive'}
      className="flex flex-col justify-center gap-1"
      isActivityView={isActivityView}>
      <div className="flex items-center gap-3">
        <TooltipRoot>
          <TooltipTrigger>
            <StatusIndicator status={submission.submissionStatus} />
          </TooltipTrigger>
          <TooltipContent>
            <CALLOUT>{getLabel(submission.submissionStatus)}</CALLOUT>
          </TooltipContent>
        </TooltipRoot>
        <div className="flex flex-col justify-center md:min-h-[46px]">
          <TableCellText weight="medium" className="flex items-center gap-1">
            <StoreIcon className="icon-sm" />
            {platformLabelMap[submission.submissionPlatform]}{' '}
            {submissionStoreMap[submission.submissionPlatform]} submission
          </TableCellText>
          {!hideTimestamp && (
            <ContentLoading loading={!renderDate} height={24} width={150}>
              <TooltipRoot>
                <TooltipTrigger>
                  <CALLOUT theme="secondary">{getVariableTimeString(submission.createdAt)}</CALLOUT>
                </TooltipTrigger>
                <TooltipContent side="bottom" align="start">
                  <TooltipText>
                    {format(new Date(submission.createdAt), dateFnsFormats.timestamp)}
                  </TooltipText>
                </TooltipContent>
              </TooltipRoot>
            </ContentLoading>
          )}
        </div>
      </div>
      <div className="hidden flex-wrap gap-4 max-md-gutters:mt-1.5 max-md-gutters:flex">
        {submission.submittedBuild && (
          <TableInlineCell>
            <CALLOUT theme="secondary">Runtime</CALLOUT>
            <CALLOUT theme="default">{submission.submittedBuild?.appVersion}</CALLOUT>
          </TableInlineCell>
        )}
        {submission.submittedBuild && (
          <TableInlineCell>
            <div className="shrink-0">
              <BuildIcon />
            </div>
            <TableCellText>{submission.submittedBuild?.id.split('-')[0]}</TableCellText>
          </TableInlineCell>
        )}
      </div>
    </TableCell>
  );

  const buildCell = submission.submittedBuild ? (
    <TableCell
      hideOnMobile
      theme={disableLinks ? 'default' : 'interactive'}
      className="flex min-h-[36px] w-full items-center gap-1 self-stretch max-md-gutters:hidden">
      <div className="shrink-0">
        <BuildIcon />
      </div>
      <TableCellText>{submission.submittedBuild?.id.split('-')[0]}</TableCellText>
    </TableCell>
  ) : (
    emptyCell
  );

  return (
    <Fragment key={submission.id}>
      <TableSeparator />
      <TableRow>
        {disableLinks ? (
          primaryCell
        ) : (
          <Link
            href={getProjectURL(accountName, submission.app.slug, `submissions/${submission.id}`)}
            className="size-full rounded-md">
            {primaryCell}
          </Link>
        )}
        {!hideAuthor && (
          <TableCell hideOnMobile className="mr-6 items-center gap-3">
            <ActorAttribution actor={submission.initiatingActor} />
          </TableCell>
        )}
        {submission.submittedBuild ? (
          <TableCell hideOnMobile className="gap-3">
            <TableCellText>{submission.submittedBuild?.appVersion}</TableCellText>
          </TableCell>
        ) : (
          emptyCell
        )}
        {disableLinks ? (
          buildCell
        ) : submission.submittedBuild ? (
          <Link
            href={getProjectURL(
              accountName,
              submission.app.slug,
              `builds/${submission.submittedBuild?.id}`
            )}
            className="flex h-full items-center max-md-gutters:hidden">
            {buildCell}
          </Link>
        ) : (
          emptyCell
        )}
      </TableRow>
    </Fragment>
  );
}

function getLabel(submissionStatus: SubmissionStatus) {
  switch (submissionStatus) {
    case SubmissionStatus.Finished:
      return 'Finished';
    case SubmissionStatus.InProgress:
      return 'Building';
    case SubmissionStatus.InQueue:
      return 'Queued';
    case SubmissionStatus.AwaitingBuild:
      return 'Awaiting Build';
    case SubmissionStatus.Canceled:
      return 'Canceled';
    case SubmissionStatus.Errored:
      return 'Failed';
    default:
      return 'Unknown';
  }
}
