import { ApolloQueryResult } from '@apollo/client';
import { FolderIcon } from '@expo/styleguide-icons/outline/FolderIcon';

import { isOwnerOrImplicitOwner } from '~/common/helpers';
import {
  AppsPaginatedQuery,
  AppsPaginatedQueryVariables,
} from '~/graphql/queries/AppsPaginatedQuery.query.generated';
import { LoggedInProps } from '~/scenes/_app/helpers';
import { Placeholder } from '~/ui/components/Placeholder';
import { TableHeader } from '~/ui/components/Table/TableHeader';
import { TableHeaderCell } from '~/ui/components/Table/TableHeaderCell';
import { TableHeaderCellText } from '~/ui/components/Table/TableHeaderCellText';
import { TableLoadMore } from '~/ui/components/Table/TableLoadMore';
import { TableRoot } from '~/ui/components/Table/TableRoot';

import { PlaceholderIllustration } from './PlaceholderIllustration';
import { ProjectRow } from './ProjectRow';
import { ProjectRowSkeleton } from './ProjectRowSkeleton';
import { usePinnedApps } from './usePinnedApps';

type Props = {
  accountName: string;
  currentUser: LoggedInProps['currentUser'];
  openProjectDialog: () => void;
  data: AppsPaginatedQuery;
  fetchMore: (options: {
    variables: Partial<AppsPaginatedQueryVariables>;
  }) => Promise<ApolloQueryResult<AppsPaginatedQuery>>;
  loading: boolean;
  canCreateProject: boolean;
};

const LIMIT_OF_PINNED_APPS = 5;

export function AllProjectsScene({
  accountName,
  currentUser,
  data,
  fetchMore,
  loading,
  openProjectDialog,
  canCreateProject,
}: Props) {
  const { edges, pageInfo } = data.account.byName.appsPaginated;
  const apps = edges.map((edge) => edge.node);

  const account = currentUser.accounts.find((account) => account.name === accountName);
  const hasOwnerRole = account ? isOwnerOrImplicitOwner(account, currentUser.username) : false;

  const { pinnedApps, setIsPinned } = usePinnedApps(accountName);

  if (apps.length === 0) {
    return (
      <Placeholder
        icon={<FolderIcon className="icon-2xl" />}
        illustration={<PlaceholderIllustration />}
        title="Start your project"
        description={
          canCreateProject ? (
            'Get started with EAS by creating a project.'
          ) : (
            <>
              Members with Viewer role cannot create a project.
              <br />
              Contact member with Owner, Admin or Developer role to add new one.
            </>
          )
        }
        buttonText={canCreateProject ? 'Create a Project' : undefined}
        openInNewTab={false}
        onClick={canCreateProject ? () => openProjectDialog() : undefined}
      />
    );
  }

  const pinnedAppIdsSet = new Set(pinnedApps.map((app) => app.id));

  return (
    <div className="flex flex-col gap-6">
      <TableRoot
        columnCount={3}
        gridTemplateColumns={[
          {
            min: 'max(40%, 220px)',
            max: '1fr',
          },
          { min: '150px', max: '1fr' },
          'min-content',
        ]}
        className="overflow-x-auto">
        <TableHeader>
          <TableHeaderCell>
            <TableHeaderCellText>Project</TableHeaderCellText>
          </TableHeaderCell>
          <TableHeaderCell>
            <TableHeaderCellText>Slug</TableHeaderCellText>
          </TableHeaderCell>
          <TableHeaderCell className="h-full" />
        </TableHeader>
        {pinnedApps.map((pinnedApp) => {
          const app = apps.find((app) => app.id === pinnedApp.id) ?? pinnedApp;

          if (!app) {
            return <ProjectRowSkeleton key={pinnedApp.id} />;
          }

          return (
            <ProjectRow
              key={pinnedApp.id}
              accountName={accountName}
              hasOwnerRole={hasOwnerRole}
              app={app}
              isPinned
              setIsPinned={setIsPinned(app.id)}
            />
          );
        })}
        {apps
          .filter((app) => !pinnedAppIdsSet.has(app.id))
          .map((app) => (
            <ProjectRow
              key={app.id}
              accountName={accountName}
              hasOwnerRole={hasOwnerRole}
              app={app}
              setIsPinned={setIsPinned(app.id)}
              isPinningDisabled={pinnedAppIdsSet.size >= LIMIT_OF_PINNED_APPS}
            />
          ))}
        {pageInfo?.hasNextPage && pageInfo?.endCursor && (
          <TableLoadMore
            loading={loading}
            onClick={async () => await fetchMore({ variables: { after: pageInfo.endCursor } })}
          />
        )}
      </TableRoot>
    </div>
  );
}
