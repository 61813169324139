import { mergeClasses } from '@expo/styleguide';

import { Button, ButtonProps } from '~/ui/components/Button';
import { TableSeparator } from '~/ui/components/Table/TableSeparator';
import { FormStates } from '~/ui/components/form/FormStates';

type Props = ButtonProps & {
  loading?: boolean;
  label?: string;
  className?: string;
};

export function TableLoadMore({ loading, label = 'Load more', className, ...rest }: Props) {
  return (
    <>
      <TableSeparator />
      <Button
        theme="secondary"
        size="xs"
        status={loading ? FormStates.LOADING : FormStates.IDLE}
        className={mergeClasses(
          'col-span-full w-full justify-center rounded-b-lg rounded-t-none border-0 bg-subtle py-5 hocus:transform-none',
          className
        )}
        {...rest}>
        {label}
      </Button>
    </>
  );
}
